@font-face {
    font-family: 'BookmanOldStyle';
    src: url('../fonts/BookmanOldStyle.ttc') format('truetype');
  }
  
  @font-face {
    font-family: 'BrushScript';
    src: url('../fonts/Brush Script.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'LoraVariableItalic';
    src: url('../fonts/Lora-VariableItalic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Paperboard';
    src: url('../fonts/Paperboard.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'SatoshiVariable';
    src: url('../fonts/Satoshi-Variable.ttf') format('truetype');
  }
  

.outlined {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff; /* White outline */
}

.filled {
  -webkit-text-fill-color: #ffffff; /* White fill */
  -webkit-text-stroke-width: 0;
}