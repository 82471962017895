.opentable-widget .ot-dtp-picker-selector {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust this value to your needs */
  }
  
  .opentable-widget .ot-select-label {
    flex-grow: 1;
  }
  
  .opentable-widget select {
    flex-grow: 1;
    max-width: 100%;
  }